import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-high-point-north-carolina.png'
import image0 from "../../images/cities/scale-model-of-john-coltrane-statue-in-high-point-north-carolina.png"
import image1 from "../../images/cities/scale-model-of-world's-largest-chest-of-drawers-in-high-point-north-carolina.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='High Point'
            state='North Carolina'
            image={image}
            lat='35.9556923'
            lon='-80.00531760000001'
            attractions={ [{"name": "John Coltrane Statue", "vicinity": "S Hamilton St & Commerce Avenue, High Point", "types": ["point_of_interest", "establishment"], "lat": 35.9557598, "lng": -80.00252449999999}, {"name": "World's Largest Chest of Drawers", "vicinity": "508 N Hamilton St, High Point", "types": ["point_of_interest", "establishment"], "lat": 35.9635767, "lng": -80.00825759999998}] }
            attractionImages={ {"John Coltrane Statue":image0,"World's Largest Chest of Drawers":image1,} }
       />);
  }
}